<template>
  <div>
    <h3 class="mb-2 mt-1">
      Dostępni psycholodzy i psychoterapeuci
    </h3>
    <BCard :title="item.first_name + ' ' + item.last_name" v-for="item in psychologists">
      <div class="mb-1">
        <span v-html="item.address"></span><br />
        <span v-html="item.post_code"></span>, <span v-html="item.city"></span>
      </div>
      <div>
        <a :href="item.website">{{item.website}}</a>
      </div>
    </BCard>
  </div>
</template>

<script>
import {BButton, BCard, BCardText, BImg, BLink, BCol, BRow} from 'bootstrap-vue'
import { email, required } from '@core/utils/validations/validations'
import Quiz from "@/views/quiz/Quiz.vue";

export default {
  components: {
    Quiz,
    BButton,
    BImg, BLink,
    BCard,
    BCardText,
    BCol,
    BRow
  },
  data() {
    return {
      psychologists: []
    }
  },
  created() {
    this.fetchPsychologists()
  },
  computed: {
  },
  methods: {
    fetchPsychologists() {
      this.$http.get(`${this.$store.state.apiDomain}/api/psychologists`).then(response => {
        this.psychologists = response.data.psychologists
      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>